<template>
  <mu-container class="base64ioer">
    <mu-paper :z-depth="2">
      <h2>数据导入/导出</h2>
      <mu-text-field v-model="base64str" placeholder="Base64编码后的数据" multi-line :rows="10" :rows-max="10" full-width></mu-text-field><br/>
      <mu-button color="success" @click="encodetobase64()">导出</mu-button>
      <mu-button color="secondary" @click="base64decode()">导入</mu-button>
    </mu-paper>
  </mu-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Base64IOer',
  data () {
    return {
      base64str: ''
    }
  },
  computed: {
    ...mapState(['cartedThreads'])
  },
  methods: {
    encodetobase64 () {
      let str = JSON.stringify(this.cartedThreads)
      str = str.replace(/[\u007F-\uFFFF]/g, function (chr) {
        return '\\u' + ('0000' + chr.charCodeAt(0).toString(16)).substr(-4)
      })
      const encodedData = window.btoa(str)
      this.base64str = encodedData
    },
    base64decode () {
      const decodedData = window.atob(this.base64str)
      const result = JSON.parse(decodedData)
      this.$store.commit('cartReplace', result)
    }
  }
}
</script>

<style lang="stylus" scoped>
.base64ioer
  margin-top 40px
  .mu-paper
    padding 10px 20px 20px 20px
  button
    margin 5px 10px 5px 10px
</style>
