<template>
  <mu-container>
    <mu-row gutter>
      <mu-col span="12" md="8">
        <template v-if="cartedThreads.length !== 0">
          <ThreadsList :threads='cartedThreads'/>
        </template>
      </mu-col>
      <mu-col span="12" md="4">
        <Base64IOer/>
        <CartOperations/>
      </mu-col>
    </mu-row>
  </mu-container>
</template>

<script>
import { mapState } from 'vuex'
import ThreadsList from '@/components/ThreadsList.vue'
import Base64IOer from '@/components/Base64IOer.vue'
import CartOperations from '@/components/CartOperations.vue'

export default {
  name: 'PageCart',
  computed: {
    ...mapState(['cartedThreads'])
  },
  components: {
    ThreadsList, Base64IOer, CartOperations
  }
}
</script>

<style lang="stylus" scoped>

</style>
