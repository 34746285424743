<template>
  <mu-container class="cartoperation">
    <mu-paper :z-depth="2">
      <h2>批量操作</h2>
      <mu-button color="success" @click="getAllBaiduPan()">获取所有网盘链接</mu-button>
      <mu-button color="warning" @click="clearCart()">清空购物车</mu-button>
      <p v-show="loadingThread">正在获取：{{ loadingThread }} <mu-circular-progress :size="16"></mu-circular-progress></p>
    </mu-paper>
  </mu-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CartOperations',
  data () {
    return {
      loadingThread: null
    }
  },
  computed: {
    ...mapState(['cartedThreads'])
  },
  methods: {
    clearCart () {
      this.$store.commit('cartReplace', [])
    },
    getBaidupan: function (thread) {
      thread.baidupan = null
      const url = `${process.env.VUE_APP_API}/baidupan/tid/${thread.tid}`
      this.$axios
        .get(url)
        .then(response => {
          thread.baidupan = response.data.fullUrl
        })
        .catch(error => {
          console.log(error)
          thread.baidupan = false
        })
    },
    getAllBaiduPan () {
      const ms = 18000
      const len = this.cartedThreads.length
      this.loadingThread = '正在获取所有网盘链接…'
      console.log('开始获取所有网盘链接…')
      for (let i = 0; i < len; i++) {
        setTimeout(() => {
          const thread = this.cartedThreads[i]
          console.log(thread.title)
          this.loadingThread = thread.title
          this.getBaidupan(thread)
        }, ms * i)
      }
      setTimeout(() => {
        this.loadingThread = null
      }, ms * (len + 1))
    }
  }
}
</script>

<style lang="stylus" scoped>
.cartoperation
  margin-top 40px
  .mu-paper
    padding 10px 20px 20px 20px
  button
    margin 5px 10px 5px 10px
</style>
